<template>
    <div class="modal" v-if="!isMobile">
        <form @submit.prevent="submit" id="modal_form" class="form">

            <div class="close" v-on:click="$emit('close_modal')">
                <div class="close__inner">
                    <div class="close__line__left"></div>
                    <div class="close__line__right"></div>
                </div>
            </div>

            <div class="filter-title">Параметры поиска</div>

            <div class="filter-row">
                
                <div>
                    <div class="f-title">Категория</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" value="3" id="buy" v-model="filter.type" />
                            <label for="buy">Куплю</label>
                        </div>
                        <div>
                            <input type="checkbox" value="1" id="sell" v-model="filter.type"/>
                            <label for="sell">Продам</label>
                        </div>
                        <div>
                            <input type="checkbox" value="4" id="lease" v-model="filter.type"/>
                            <label for="lease">Сниму</label>
                        </div>
                        <div>
                            <input type="checkbox" value="2" id="rent" v-model="filter.type"/>
                            <label for="rent">Сдам</label>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Цена</div>
                    <div class="from-to-group from-to-group--long space">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.price1" placeholder="Цена от"/>
                            <input type="text" autocomplete="off" v-model="filter.price2" placeholder="до"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Комнат</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="-1" id="studio"/>
                            <label for="studio">Студия</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="1" id="one"/>
                            <label for="one">1</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="2" id="two"/>
                            <label for="two">2</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="3" id="three"/>
                            <label for="three">3</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="4" id="four"/>
                            <label for="four">4+</label>
                        </div>
                    </div>
                </div>
            </div>

             <div class="filter-row">

                <div>
                    <div class="f-title">Общая площадь</div>
                    <div class="from-to-group space">
                        <div>
                            <input type="text" name="square_from" v-model="filter.sizes_full1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="square_to" v-model="filter.sizes_full2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Жилая площадь</div>
                    <div class="from-to-group space">
                        <div>
                            <input type="text" name="kitchen_from" v-model="filter.sizes_living1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="kitchen_to" v-model="filter.sizes_living2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Площадь кухни</div>
                    <div class="from-to-group space">
                        <div>
                            <input type="text" name="kitchen_from" v-model="filter.sizes_kitchen1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="kitchen_to" v-model="filter.sizes_kitchen2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Этаж</div>
                    <div class="from-to-group space">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.floor1" placeholder="От"/>
                            <input type="text" autocomplete="off" v-model="filter.floor2" placeholder="до"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Этажей всего</div>
                    <div class="from-to-group space">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.full_floor1" placeholder="От"/>
                            <input type="text" autocomplete="off" v-model="filter.full_floor2" placeholder="до"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="filter-row">
                
                <div>
                    <div class="f-title">Район</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" value="1" id="district1" v-model="filter.district" />
                            <label for="district1">Центр.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="2" id="district2" v-model="filter.district" />
                            <label for="district2">Совет.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="3" id="district3" v-model="filter.district" />
                            <label for="district3">Октяб.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="4" id="district4" v-model="filter.district" />
                            <label for="district4">Желез.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="5" id="district5" v-model="filter.district" />
                            <label for="district5">Сверд.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="6" id="district6" v-model="filter.district" />
                            <label for="district6">Киров.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="7" id="district7" v-model="filter.district" />
                            <label for="district7">Ленин.</label>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Улица</div>
                    <div class="text-input">
                        <div>
                            <input type="text" v-model="filter.address" placeholder="Улица" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Наличие фото</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.photo" value="1" id="photo_yes"/>
                            <label for="photo_yes">Есть</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.photo" value="0" id="photo_no"/>
                            <label for="photo_no">Нет</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-row">
                <div>
                    <div class="f-title">Тип объявления</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.person" value="0" id="agent"/>
                            <label for="agent">Собственник</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.person" value="1" id="owner"/>
                            <label for="owner">Агенство</label>
                        </div>
                    </div>
                </div>
                
                <div>
                    <div class="f-title">Телефон</div>
                    <div class="text-input text-input--long">
                        <div>
                            <input type="text" v-model="filter.phone" placeholder="Телефон" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Тип стен</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.material" value="0" id="material0"/>
                            <label for="material0">Монолит</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="1" id="material1"/>
                            <label for="material1">Кирпич</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="2" id="material2"/>
                            <label for="material2">Панель</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="3" id="material3"/>
                            <label for="material3">Дерево</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="4" id="material4"/>
                            <label for="material4">Блочный</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="filter-row">
                <div>
                    <div class="f-title">Вид объекта</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.sub_category" value="0" id="new"/>
                            <label for="new">Новострой</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.sub_category" value="1" id="old"/>
                            <label for="old">Вторичка</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Срок сдачи</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="0" id="ready0"/>
                            <label for="ready0">Сдан</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="1" id="ready1"/>
                            <label for="ready1">2021</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="2" id="ready2"/>
                            <label for="ready2">2022</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="3" id="ready3"/>
                            <label for="ready3">Позже</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Комиссия</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.comission" value="0" id="comission_yes"/>
                            <label for="comission_yes">Есть</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.comission" value="1" id="comission_no"/>
                            <label for="comission_no">Нет</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Срок аренды</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.rent_time" value="0" id="long"/>
                            <label for="long">Длительно</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rent_time" value="1" id="short"/>
                            <label for="short">Посуточно</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="filter-row">
                <button type="submit" class="submit-button">Поиск</button>
            </div>

        </form>
    </div>
    <div class="mobile-modal" v-else>
        <form @submit.prevent="submit" id="modal_form" class="mobile-form">

            <div class="close" v-on:click="$emit('close_modal')">
                <div class="close__inner">
                    <div class="close__line__left"></div>
                    <div class="close__line__right"></div>
                </div>
            </div>

            <div class="filter-title">Параметры поиска</div>

            <div class="mobile-filter-row">
                
                <div>
                    <div class="f-title">Категория</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" value="3" id="buy" v-model="filter.type" />
                            <label for="buy">Куплю</label>
                        </div>
                        <div>
                            <input type="checkbox" value="1" id="sell" v-model="filter.type"/>
                            <label for="sell">Продам</label>
                        </div>
                        <div>
                            <input type="checkbox" value="4" id="lease" v-model="filter.type"/>
                            <label for="lease">Сниму</label>
                        </div>
                        <div>
                            <input type="checkbox" value="2" id="rent" v-model="filter.type"/>
                            <label for="rent">Сдам</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Комнат</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="0" id="studio"/>
                            <label for="studio">Студия</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="1" id="one"/>
                            <label for="one">1</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="2" id="two"/>
                            <label for="two">2</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="3" id="three"/>
                            <label for="three">3</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rooms" value="4" id="four"/>
                            <label for="four">4 и более</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Цена</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.price1" placeholder="Цена от"/>
                            <input type="text" autocomplete="off" v-model="filter.price2" placeholder="до"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Общая площадь</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" name="square_from" v-model="filter.sizes_full1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="square_to" v-model="filter.sizes_full2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">

                <div>
                    <div class="f-title">Жилая площадь</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" name="kitchen_from" v-model="filter.sizes_living1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="kitchen_to" v-model="filter.sizes_living2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Площадь кухни</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" name="kitchen_from" v-model="filter.sizes_kitchen1" placeholder="От" autocomplete="off"/>
                            <input type="text" name="kitchen_to" v-model="filter.sizes_kitchen2" placeholder="до" autocomplete="off"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mobile-filter-row">

                <div>
                    <div class="f-title">Этаж</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.floor1" placeholder="От"/>
                            <input type="text" autocomplete="off" v-model="filter.floor2" placeholder="до"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Этажей всего</div>
                    <div class="from-to-group mobile-from-to-group">
                        <div>
                            <input type="text" autocomplete="off" v-model="filter.full_floor1" placeholder="От"/>
                            <input type="text" autocomplete="off" v-model="filter.full_floor2" placeholder="до"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Телефон</div>
                    <div class="text-input">
                        <div>
                            <input type="text" v-model="filter.phone" placeholder="Телефон" autocomplete="off"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Собственник</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.person" value="0" id="agent"/>
                            <label for="agent">Да</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.person" value="1" id="owner"/>
                            <label for="owner">Нет</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mobile-filter-row">

                <div>
                    <div class="f-title">Улица</div>
                    <div class="text-input">
                        <div>
                            <input type="text" v-model="filter.address" placeholder="Улица" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="f-title">Наличие фото</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.photo" value="1" id="photo_yes"/>
                            <label for="photo_yes">Есть</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.photo" value="0" id="photo_no"/>
                            <label for="photo_no">Нет</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                
                <div>
                    <div class="f-title">Район</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" value="1" id="district1" v-model="filter.district" />
                            <label for="district1">Центр.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="2" id="district2" v-model="filter.district" />
                            <label for="district2">Совет.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="3" id="district3" v-model="filter.district" />
                            <label for="district3">Октяб.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="4" id="district4" v-model="filter.district" />
                            <label for="district4">Желез.</label>
                        </div>
                    </div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" value="5" id="district5" v-model="filter.district" />
                            <label for="district5">Сверд.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="6" id="district6" v-model="filter.district" />
                            <label for="district6">Киров.</label>
                        </div>
                        <div>
                            <input type="checkbox" value="7" id="district7" v-model="filter.district" />
                            <label for="district7">Ленин.</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mobile-filter-row">

                <div>
                    <div class="f-title">Тип стен</div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.material" value="3" id="material3"/>
                            <label for="material3">Деревянный</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="1" id="material1"/>
                            <label for="material1">Кирпичный</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="4" id="material4"/>
                            <label for="material4">Блочный</label>
                        </div>
                    </div>
                    <div class="check-group space">
                        <div>
                            <input type="checkbox" v-model="filter.material" value="0" id="material0"/>
                            <label for="material0">Монолитный</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.material" value="2" id="material2"/>
                            <label for="material2">Панельный</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Комиссия</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.comission" value="0" id="comission_yes"/>
                            <label for="comission_yes">Есть</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.comission" value="1" id="comission_no"/>
                            <label for="comission_no">Нет</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-title">Срок аренды</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.rent_time" value="0" id="long"/>
                            <label for="long">Длит.</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.rent_time" value="1" id="short"/>
                            <label for="short">Посут.</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Срок сдачи</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="0" id="ready0"/>
                            <label for="ready0">Сдан</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="1" id="ready1"/>
                            <label for="ready1">2021</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="2" id="ready2"/>
                            <label for="ready2">2022</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.ready" value="3" id="ready3"/>
                            <label for="ready3">2022+</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-filter-row">
                <div>
                    <div class="f-title">Вид объекта</div>
                    <div class="check-group">
                        <div>
                            <input type="checkbox" v-model="filter.sub_category" value="0" id="new"/>
                            <label for="new">Новост.</label>
                        </div>
                        <div>
                            <input type="checkbox" v-model="filter.sub_category" value="1" id="old"/>
                            <label for="old">Вторич.</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mobile-filter-row">
                <button type="submit" class="submit-button">Поиск</button>
            </div>

        </form>
    </div>
</template>

<script>
export default {
    methods: {
        modal_close() {
            this.$emit('close_modal');
        },
        submit() {
            this.modal_close();
            this.paramsSubmit();
        }

    }
}
</script>

<style lang="scss" scoped>
.mobile-filter-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 17px;
    &:last-of-type {
        margin-top: 30px;
        margin-bottom: 0;
    }
}
.filter-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.f-title {
    font: 14px proxima-bold;
    color: rgb(15, 42, 72);
    letter-spacing: 0.01em;
    margin-bottom: 7px;
}

.space {
    margin-right: 20px;
}
.mobile-modal {
    display: flex;
    position: fixed;
    max-width: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 450;
}
.mobile-form {
    margin: auto;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 100%;
    background: white;
    padding: 25px;
    will-change: transform;
}
.modal {
    display: flex;
    position: fixed;
    padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    z-index: 50;
}
.form {
    margin: auto;
    width: 920px;
    border-radius: 4px;
    background: white;
    padding: 35px 45px 35px 45px;
    will-change: transform;
}
.close {
    display: flex;
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
    width: 45px;
    height: 45px;
    border-radius: 3px;
    cursor: pointer;
    &__inner {
        margin: auto;
        width: 13px;
        height: 13px;
        transform: rotate(45deg);
        cursor: pointer;
    }
    &__line__left {
        width: 14px;
        margin-top: 6px;
        height: 2px;
        background: rgb(220, 220, 220);
    }
    &__line__right {
        width: 2px;
        margin-top: -8px;
        margin-left: 6px;
        height: 14px;
        background: rgb(220, 220, 220);
    }
    &:hover .close__line__left {
        background: rgb(15, 42, 72);
    }
    &:hover .close__line__right {
        background: rgb(15, 42, 72);
    }
}
.filter-title {
    font: 18px proxima-bold;
    letter-spacing: 0.01em;
    /*line-height: 30px;*/
    margin-bottom: 20px;
    color: rgb(15, 42, 72);
}
</style>