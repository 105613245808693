<template>
    <div v-if="!isMobile && view !== 'compact'" class="home" @click="$emit('click', $event)">
        <img class="home__photo" v-if="home.images.length" :src="home.images[0].imgurl" @error="replaceByDefault" />
        <img class="home__photo" v-else src="../assets/bg.png"/>
        <div class="home__column">
            <div class="home__title">
                <h3>{{ title }}</h3>
                <div class="fav" @click.stop="!isFav ? addFav(home.id) : removeFav(home.id)">
                    <svg :class="{ fav__full: isFav }" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
                </div>
            </div>
            <div class="home__content">
                <div class="home__info">
                    <div class="home__text text-m gray">{{ type }}</div>
                    <div class="home__text text-m gray">{{ home.sub_category ? home.sub_category : category }}</div>
                    <div class="home__text text-m gray">{{ home.district }} район</div>
                    <div class="home__text text-m gray">{{ created }}</div>
                </div>

                <div class="home__plans">
                    <div class="home__text">
                        <span class="text-m gray">Этаж: </span>
                        <span class="text-m blue">{{ home.floor.one ? home.floor.one : '?' }} {{ home.floor.full ? ` из ${home.floor.full}` : 'из ?' }}</span>
                    </div>
                    <div class="home__text">
                        <span class="text-m gray">Комнат: </span>
                        <span class="text-m blue">
                            {{ 
                                home.full_rooms && home.category === 3
                                ? `${home.rooms} из ${home.full_rooms}` 
                                : home.rooms ? home.rooms === -1 ? 'Студия' : home.rooms : '?' 
                            }}
                        </span>
                    </div>
                    <div class="home__text">
                        <span class="text-m gray">Площадь: </span>
                        <span class="text-m blue">{{ home.sizes.full ? `${home.sizes.full} ${ home.category === 5 ? 'сот.' : 'м²'}` : '?' }}</span>
                    </div>
                    <div class="home__text text-m gray">
                        <span class="text-m gray">Цена: </span>
                        <span class="text-m blue">{{ home.price > 1000 ? home.price / 1000 + ' тыс.' : home.price }} руб. {{ home.type === 2 ? home.rent_type === 'Посуточно' ? '/ сут.' : '/ мес.' : ''}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="mobile-home" @click="$emit('click', $event)">
        <img class="mobile-home__photo" v-if="home.images.length" :src="home.images[0].imgurl" @error="replaceByDefault" />
        <img class="mobile-home__photo" v-else src="../assets/bg.png"/>
        <div class="mobile-home__column">
            <div class="mobile-home__title">
                <div class="mobile-home__title-text">
                    <p class="text_small_bold">{{ title }}</p>
                </div>
                <div class="fav" @click.stop="!isFav ? addFav(home.id) : removeFav(home.id)">
                    <svg :class="{ fav__full: isFav }" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
                </div>
            </div>
            <div class="home__content">
                <div class="mobile-home__info">
                    <div class="text_small gray">{{ type }}</div>
                    <div class="text_small gray">{{ home.sub_category ? home.sub_category : category }}</div>
                    <div class="text_small gray">{{ home.district.replace('район', '') }}</div>
                    <div class="text_small gray">{{ created }}</div>
                </div>

                <div class="mobile-home__plans">
                    <div class="mobile-home__text">
                        <span class="text_small gray">Этаж: </span>
                        <span class="text_small blue">{{ home.floor.one ? home.floor.one : '?' }} {{ home.floor.full ? ` из ${home.floor.full}` : 'из ?' }}</span>
                    </div>
                    <div class="mobile-home__text">
                        <span class="text_small gray">Комнат: </span>
                        <span class="text_small blue">
                            {{ 
                                home.full_rooms && home.category === 3
                                ? `${home.rooms} из ${home.full_rooms}` 
                                : home.rooms ? home.rooms === -1 ? 'Студия' : home.rooms : '?' 
                            }}
                        </span>
                    </div>
                    <div class="mobile-home__text">
                        <span class="text_small gray">Площадь: </span>
                        <span class="text_small blue">{{ home.sizes.full ? `${home.sizes.full} ${ home.category === 5 ? 'сот.' : 'м²'}` : '?' }}</span>
                    </div>
                    <div class="mobile-home__text gray">
                        <span class="text_small gray">Цена: </span>
                        <span class="text_small blue">{{ home.price > 1000 ? home.price / 1000 + ' тыс.' : home.price }} руб. {{ home.type === 2 ? home.rent_type === 'Посуточно' ? '/ сут.' : '/ мес.' : ''}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img from '@/assets/bg.png'

export default {
    props: ['home', 'home_id', 'view'],
    computed: {
        isFav() {
            return this.favs.includes(this.home.id);
        },
        title() {
            return this.home.title.replace('Красноярский край, Красноярск', '').replace(' в ', '');
        },
        type() {
            var result = '';

            switch (this.home.type) {
                case 1: {
                    result = 'Продам'
                    break;
                }
                case 2: {
                    result = 'Сдам'
                    break;
                }
                case 3: {
                    result = 'Куплю'
                    break;
                }
                case 4: {
                    result = 'Сниму'
                    break;
                }
            }
            return result;
        },
        category() {
            var result = '';

            switch (this.home.category) {
                case 2: {
                    result = 'Квартира'
                    break;
                }
                case 3: {
                    result = 'Комната'
                    break;
                }
                case 4: {
                    result = 'Дом, коттедж, дача'
                    break;
                }
                case 5: {
                    result = 'Земельный участок'
                    break;
                }
                case 6: {
                    result = 'Гараж'
                    break;
                }
                case 7: {
                    result = 'Коммерческое здание'
                    break;
                }
            }
            return result;
        },
        created() {
            const date = this.home.created.substring(5,10);
            const time = this.home.created.substring(11,16);
            const month = date.substring(0,2)
            const day = date.substring(3,5)

            var result = '';

            switch (month) {
                case '01': 
                    result = 'янв'
                    break;

                case '02': 
                    result = 'фев'
                    break;
                
                case '03': 
                    result = 'мар'
                    break;
                
                case '04': 
                    result = 'апр'
                    break;
                
                case '05': 
                    result = 'мая'
                    break;
                
                case '06': 
                    result = 'июн'
                    break;
                
                case '07': 
                    result = 'июл'
                    break;

                case '08': 
                    result = 'авг'
                    break;
                
                case '09': 
                    result = 'сен'
                    break;
                
                case '10': 
                    result = 'окт'
                    break;
                
                case '11': 
                    result = 'ноя'
                    break;
                
                case '12': 
                    result = 'дек'
                    break;
                
            }
            return `${day} ${result} ${time}`;
        }
    },
    methods: {
        replaceByDefault(e) {
            e.target.src = img
        },
    }
}
</script>

<style scoped>
.mobile-home {
    margin-bottom: 30px;
    height: fit-content !important;
    max-height:100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 10px rgba(40, 110, 156, 0.12), 0 3px 10px rgba(40, 110, 156,  0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 4px;
}
.home {
    margin-bottom: 30px;
    width: 950px;
    display: flex;
    box-shadow: 0 3px 10px rgba(40, 110, 156, 0.12), 0 3px 10px rgba(40, 110, 156,  0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 4px;
}
.home:hover {
    box-shadow: 0px 7px 25px rgba(40, 110, 156, 0.2), 0 7px 25px rgba(40, 110, 156, 0.20);
    cursor: pointer !important;
}
.mobile-home__photo {
    object-fit: cover;
    width: 100% !important;
    height: 150px !important;
    border-radius: 4px 4px 0px 0px;
    background: rgb(255, 255, 255);
    flex: 1 1 1;
}
.home__photo {
    object-fit: cover;
    width: 294px;
    height: 210px;
    border-radius: 4px 0 0 4px;
    background: rgb(255, 255, 255);
    flex: 1 1 1;
}
.mobile-home__column {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    flex: 1 1 0;
}
.home__column {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    flex: 1 1 0;
}
.mobile-home__title {
    display: flex;
    justify-content: space-between;
    color: #376794;
    margin-top: 5px;
    height: 15px;
}
.mobile-home__title-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
}
.home__title {
    display: flex;
    justify-content: space-between;
    color: #376794;
    margin-top: 10px;
    height: 35px;
}
.home__content {
    display: flex;
}

.mobile-home__text {
    line-height: 5px;
}

.home__text {
    line-height: 26px;
}
.text_small_bold {
    font: 12px proxima-bold;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.text_small {
    font: 11px proxima-regular;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.text_ultra_gray {
    font: 14px proxima-regular;
    color: rgba(180, 180, 180, 1);
}

.mobile-home__info {
    padding-top: 18px;
    width: 51vw;
}
.home__info {
    margin: 0 5px;
    padding-top: 25px;
    width: 320px;
}

.mobile-home__plans {
    padding-top: 18px;
    width: 48vw;
}
.home__plans {
    padding-top: 25px;
    width: 240px;
}
.home__payment {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home__price {
    max-width: 100%;
    display: flex;
}
.home__price-half {
    width: 50%;
}
.home__price-row {
    display: flex;
}
.home__price-line {
    margin: 16px 3px 0 3px;
    flex: 1 1 auto;
    background: rgba(240, 240, 240, 1);
    height: 1px;
}
.fav__full {
    fill: rgb(255, 208, 0)
}
.fav {
    fill: #bacada;
}

</style>