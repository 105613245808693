<template>
    <div class="categories">
        <div v-for="cat of categories" 
            :key="cat.title" 
            class="text-s category"
            :class="{'category-active': filter.category.includes(String(cat.id))}"
            @click="pickCategory(cat.id)">
                {{cat.title}}
                <div class="category__button">
                    <div class="line__top" :class="{'line__top--hidden': !filter.category.includes(String(cat.id))}"></div>
                    <div class="line__bot" :class="{'line__bot--hidden': !filter.category.includes(String(cat.id))}"></div>
                </div>
        </div>
        <div class="text-s category"
            :class="{'category-active': filter.favs}"
            @click="pickFavs">
                Избранные
                <div class="category__button">
                    <div class="line__top" :class="{'line__top--hidden': !filter.favs}"></div>
                    <div class="line__bot" :class="{'line__bot--hidden': !filter.favs}"></div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            categories: [
                { title: 'Квартиры', id: 2 },
                { title: 'Комнаты', id: 3 },
                { title: 'Дома', id: 4 },
                { title: 'Участки', id: 5 },
                { title: 'Гаражи', id: 6 },
                { title: 'Коммерческие', id: 7 }
            ]
        }
    },
    methods: {
        pickCategory(id) {
            if (this.filter.category.includes(String(id))) {
                this.filter.category.splice(this.filter.category.indexOf(String(id)), 1)
                this.paramsSubmit()
                return
            }
            this.filter.category.push(String(id))
            this.paramsSubmit()
        },
        pickFavs() {
            if (this.filter.favs) {
                this.filter.favs = false
                this.paramsSubmit()
                return
            }
            this.filter.favs = true
            this.paramsSubmit()
        }
    }
}
</script>

<style lang="scss" scoped>
.categories {
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    padding: 10px 0;
    &::-webkit-scrollbar {
        display: none;
    }
}
.category {
    display: flex;
    align-items: center;
    flex: 1 1 1;
    margin-top: 10px;
    margin-right: 5px;
    padding: 0 20px;
    height: 34px;
    background: rgb(243, 243, 243);
    border-radius: 30px;
    line-height: 34px;
    &-active {
        background: rgb(224, 240, 255);
    }
    &:last-of-type {
        margin-right: 0;
    }
    &__button {
        margin-left: 5px;
        margin-top: 8px;
        transform: rotate(45deg);
        width: 12px;
        height: 12px;
        cursor: pointer;
        border-radius: 20px;
    }
}
.line__top {
    margin: 0px 0px 0px 0px;
    width: 12px;
    height: 2px;
    background: rgb(50, 74, 102);
    &--hidden {
        background: rgb(243, 243, 243);
    }
}
.line__bot {
    margin: -7px 0px 0px 5px;
    width: 2px;
    height: 12px;
    background: rgb(50, 74, 102);
    &--hidden {
        background: rgb(243, 243, 243);
    }
}
</style>