<template>
    <div :class="['content__170', scrolled  ? 'left__categories--scrolled' : 'left__categories']">
        <div class="category" v-for="category in categories" :key="category.title">

            <div class="category__name">{{ category.title }}</div>

            <div class="category__text" v-for="sub of category.sub_categories" :key="sub.title">
                <div
                        class="link_gray"
                        :class="{'category__subtitle': filter.category.includes(String(sub.id))}"
                        @click="pickCategory(sub.id)"
                >
                    {{ sub.title }}
                    <sup class="category__count"><small>
                        {{ count[sub.id] }}
                    </small></sup>
                </div>
            </div>

        </div>
        <div class="category__text">
            <div class="link_gray" :class="{'category__subtitle': filter.favs}" @click="pickFavs">
                Избранные
                <sup class="category__count">
                    <small>
                        {{ count.favs }}
                    </small>
                </sup>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['scrolled'],
    data() {
        return {
            categories: [
                { 
                    title:  'Жилое',
                    sub_categories: [
                        { title: 'Квартиры', id: 2 },
                        { title: 'Комнаты', id: 3 },
                        { title: 'Дома, дачи, коттеджи', id: 4 },
                        { title: 'Земельные участки', id: 5 },
                    ]
                },
                { 
                    title:  'Нежилое',
                    sub_categories: [
                        { title: 'Гаражи', id: 6 },
                        { title: 'Коммерческие здания', id: 7 },
                    ]
                },
            ]
        }
    },
    computed: mapGetters(['count']),
    methods: {
        pickCategory(id) {
            if (this.filter.category.includes(String(id))) {
                this.filter.category.splice(this.filter.category.indexOf(String(id)), 1)
                this.paramsSubmit()
                return
            }
            this.filter.category.push(String(id))
            this.paramsSubmit()
        },
        pickFavs() {
            if (this.filter.favs) {
                this.filter.favs = false
                this.paramsSubmit()
                return
            }
            this.filter.favs = true
            this.paramsSubmit()
        }
    }
}
</script>

<style lang="scss" scoped>
.left__categories {
    position: relative;
    margin-top: 12px;
    margin-left: calc((100vw - 1250px) / 2);
    &--scrolled {
        position: fixed;
        z-index: 35;
        top: 160px;
        left: calc((100vw - 1210px) / 2);
    }
}
.category {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(230, 230, 230);
    &__subtitle {
        color: rgb(36, 89, 124);
    }
    &__name {
        font: 14px proxima-bold;
        letter-spacing: 0.01em;
        color: rgb(36, 89, 124);
        margin-bottom: 14px;
    }
    &__text {
        font: 13px proxima-regular;
        letter-spacing: 0.01em;
        margin-bottom: 8px;
    }
    &__count {
        color: rgb(34, 128, 190);
        font: 12.5px proxima-regular;
    }
    &:last-of-type {
       border-bottom: 0;
        margin-bottom: 0; 
    }
}
</style>