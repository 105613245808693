<template>
    <div v-if="!isMobile" :class="['filters-menu-bar', !scrolled ? 'filters-menu-bar--hidden' : '', isMap  ? 'filters-menu-bar--map' : '']">
        <form @submit.prevent="submit()" id="search_form" class="filter">

            <div class="container" v-bind:class="{container__open: isOpen}">
                <div class="inner" v-bind:class="{inner__open: isOpen}">

                    <div class="filter__row">

                        <div>
                            <div class="f-title text-bold">Категория</div>
                            <div class="check-group space">
                                <div>
                                    <input type="checkbox" v-model="filter.type" value="3" id="done"/>
                                    <label for="done">Куплю</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.type" value="1" id="2020"/>
                                    <label for="2020">Продам</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.type" value="4" id="2021"/>
                                    <label for="2021">Сниму</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.type" value="2" id="2022"/>
                                    <label for="2022">Сдам</label>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div class="f-title text-bold">Цена</div>
                            <div class="from-to-group from-to-group--long space">
                                <div>
                                    <input type="text" autocomplete="off" v-model="filter.price1" placeholder="Цена от"/>
                                    <input type="text" autocomplete="off" v-model="filter.price2" placeholder="до"/>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div class="f-title text-bold">Комнат</div>
                            <div class="check-group space">
                                <div>
                                    <input type="checkbox" v-model="filter.rooms" value="-1" id="studio"/>
                                    <label for="studio">Студии</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.rooms" value="1" id="one"/>
                                    <label for="one">1</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.rooms" value="2" id="two"/>
                                    <label for="two">2</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.rooms" value="3" id="three"/>
                                    <label for="three">3</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.rooms" value="4" id="four"/>
                                    <label for="four">4+</label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="filter__row filter__params" style="margin-top: 24px; margin-left: auto;">
                        <div class="more">
                        <span @click="$emit('show')" class="link_ultra-blue">
                            Все параметры
                        </span>
                            <div class="mark" v-bind:class="{mark__opened: isOpen}">
                                <div class="mark__inner">
                                    <div class="mark__line-top"></div>
                                    <div class="mark__line-bot"></div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="button">Поиск</button>
                    </div>

                </div>
            </div>


            <div class="container__full" v-bind:class="{container__full__open: isOpen}">
                <div class="inner__full" v-bind:class="{inner__full__open: isOpen}">

                    <div class="filter__row">

                        <div>
                            <div class="f-title text-bold">Общая площадь</div>
                            <div class="from-to-group space2">
                                <div>
                                    <input type="text" name="square_from" v-model="filter.sizes_full1" placeholder="От" autocomplete="off"/>
                                    <input type="text" name="square_to" v-model="filter.sizes_full2" placeholder="до" autocomplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Жилая площадь</div>
                            <div class="from-to-group space2">
                                <div>
                                    <input type="text" name="kitchen_from" v-model="filter.sizes_living1" placeholder="От" autocomplete="off"/>
                                    <input type="text" name="kitchen_to" v-model="filter.sizes_living2" placeholder="до" autocomplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Площадь кухни</div>
                            <div class="from-to-group space2">
                                <div>
                                    <input type="text" name="kitchen_from" v-model="filter.sizes_kitchen1" placeholder="От" autocomplete="off"/>
                                    <input type="text" name="kitchen_to" v-model="filter.sizes_kitchen2" placeholder="до" autocomplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Этаж</div>
                            <div class="from-to-group space2">
                                <div>
                                    <input type="text" autocomplete="off" v-model="filter.floor1" placeholder="От"/>
                                    <input type="text" autocomplete="off" v-model="filter.floor2" placeholder="до"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Этажей всего</div>
                            <div class="from-to-group space2">
                                <div>
                                    <input type="text" autocomplete="off" v-model="filter.full_floor1" placeholder="От"/>
                                    <input type="text" autocomplete="off" v-model="filter.full_floor2" placeholder="до"/>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="filter__row">
                        
                        <div>
                            <div class="f-title text-bold">Район</div>
                            <div class="check-group">
                                <div>
                                    <input type="checkbox" value="1" id="district1" v-model="filter.district" />
                                    <label for="district1">Центр.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="2" id="district2" v-model="filter.district" />
                                    <label for="district2">Совет.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="3" id="district3" v-model="filter.district" />
                                    <label for="district3">Октяб.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="4" id="district4" v-model="filter.district" />
                                    <label for="district4">Желез.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="5" id="district5" v-model="filter.district" />
                                    <label for="district5">Сверд.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="6" id="district6" v-model="filter.district" />
                                    <label for="district6">Киров.</label>
                                </div>
                                <div>
                                    <input type="checkbox" value="7" id="district7" v-model="filter.district" />
                                    <label for="district7">Ленин.</label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Улица</div>
                            <div class="text-input">
                                <div>
                                    <input type="text" v-model="filter.address" placeholder="Улица" autocomplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Наличие фото</div>
                            <div class="check-group space">
                                <div>
                                    <input type="checkbox" v-model="filter.photo" value="1" id="photo_yes"/>
                                    <label for="photo_yes">Есть</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.photo" value="0" id="photo_no"/>
                                    <label for="photo_no">Нет</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="filter__row">
                        <div>
                            <div class="f-title text-bold">Тип объявления</div>
                            <div class="check-group">
                                <div>
                                    <input type="checkbox" v-model="filter.person" value="0" id="agent"/>
                                    <label for="agent">Собственник</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.person" value="1" id="owner"/>
                                    <label for="owner">Агенство</label>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div class="f-title text-bold">Телефон</div>
                            <div class="text-input text-input--long">
                                <div>
                                    <input type="text" v-model="filter.phone" placeholder="Телефон" autocomplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="f-title text-bold">Тип стен</div>
                            <div class="check-group space">
                                <div>
                                    <input type="checkbox" v-model="filter.material" value="0" id="material0"/>
                                    <label for="material0">Монолит</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.material" value="1" id="material1"/>
                                    <label for="material1">Кирпич</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.material" value="2" id="material2"/>
                                    <label for="material2">Панель</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.material" value="3" id="material3"/>
                                    <label for="material3">Дерево</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.material" value="4" id="material4"/>
                                    <label for="material4">Блочный</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="filter__row">
                        <div>
                            <div class="f-title text-bold">Вид объекта</div>
                            <div class="check-group">
                                <div>
                                    <input type="checkbox" v-model="filter.sub_category" value="0" id="new"/>
                                    <label for="new">Новострой</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.sub_category" value="1" id="old"/>
                                    <label for="old">Вторичка</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="f-title text-bold">Срок сдачи</div>
                            <div class="check-group">
                                <div>
                                    <input type="checkbox" v-model="filter.ready" value="0" id="ready0"/>
                                    <label for="ready0">Сдан</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.ready" value="1" id="ready1"/>
                                    <label for="ready1">2021</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.ready" value="2" id="ready2"/>
                                    <label for="ready2">2022</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.ready" value="3" id="ready3"/>
                                    <label for="ready3">Позже</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="f-title text-bold">Комиссия</div>
                            <div class="check-group">
                                <div>
                                    <input type="checkbox" v-model="filter.comission" value="0" id="comission_yes"/>
                                    <label for="comission_yes">Есть</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.comission" value="1" id="comission_no"/>
                                    <label for="comission_no">Нет</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="f-title text-bold">Срок аренды</div>
                            <div class="check-group space">
                                <div>
                                    <input type="checkbox" v-model="filter.rent_time" value="0" id="long"/>
                                    <label for="long">Длительно</label>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="filter.rent_time" value="1" id="short"/>
                                    <label for="short">Посуточно</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="mobile-filters-menu-bar" v-else>
        <input type="text"
            class="mobile-input-title"
            v-model="filter.title"
            placeholder="Название"
            autocomplete="off"
        />
        <button @click="paramsSubmit" class="mobile-button">
            <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512.005 512.005">
                <path d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
                    S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
                    c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
                    M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"/>
            </svg>
        </button>
        <svg @click="openModal" class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 101.6">
            <path d="M6.7,27h53.2c1,5,5.5,9.1,11,9.1s10-4.1,11-9.1h11.4c0.9,0,1.7-0.6,1.7-1.5S94.2,24,93.3,24H82c-0.7-6-5.4-9.9-11.1-9.9
                S60.4,18,59.8,24H6.7C5.8,24,5,24.6,5,25.5S5.8,27,6.7,27z M70.9,17.2c4.3,0,7.8,3.5,7.8,7.8s-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8
                C63.1,20.7,66.6,17.2,70.9,17.2z"/>
            <path d="M93.3,49H47.8c-0.7-6-5.4-9.9-11.1-9.9S26.3,43,25.6,49H6.7C5.8,49,5,49.6,5,50.5S5.8,52,6.7,52h19c1,5,5.5,9.1,11,9.1
                s10-4.1,11-9.1h45.6c0.9,0,1.7-0.6,1.7-1.5S94.2,49,93.3,49z M36.7,57.8c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8
                S41,57.8,36.7,57.8z"/>
            <path d="M93.3,74H70.6c-0.7-6-5.4-9.9-11.1-9.9S49,68,48.4,74H6.7C5.8,74,5,74.6,5,75.5S5.8,77,6.7,77h41.8c1,6,5.5,9.1,11,9.1
                s10-3.1,11-9.1h22.8c0.9,0,1.7-0.6,1.7-1.5S94.2,74,93.3,74z M59.5,82.9c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8
                S63.8,82.9,59.5,82.9z"/>
        </svg>

        <modal name="mobile" v-if="isModal" @close_modal="closeModal" @submit="paramsSubmit" />
    </div>
</template>

<script>
import Modal from '@/components/Modal';
export default {
    props: ['scrolled', 'isOpen'],
    components: {
        Modal,
    },
    data() {
        return {
            isModal: false,
        };
    },
    computed: {
        isMap() {
            return this.$route.name === 'map'
        }
    },
    methods: {
        submit() {
            if (this.isOpen) this.$emit('show');
            this.paramsSubmit('filter')
        },
        openModal() {
            var nav = document.getElementsByClassName('mobile-nav')
            nav[0].style.zIndex = 2;
            document.querySelector('html').style.overflowY = 'hidden';
            this.isModal = true;
        },
        closeModal() {
            var nav = document.getElementsByClassName('mobile-nav')
            nav[0].style.zIndex = 33;
            document.querySelector('html').style.overflowY = 'scroll';
            this.isModal = false;
        }
    }
}
</script>

<style scoped>
    .mobile-filters-menu-bar {
        margin-top: 70px;
        height: 70px;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        background: rgb(248, 248, 251);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgb(220, 220, 220);
    }
    .mobile-input-title {
        border-radius: 3px;
        height: 35px;
        padding: 0;
        border: 1px solid rgb(220, 220, 220);
        font: 13px proxima-regular;
        /*line-height: 27px;*/
        line-height: 0;
        text-indent: 12px;
        width: 70%;
    }
    .mobile-button {
        border: 0;
        height: 35px;
        padding: 0 11px;
        border-radius: 60px;
        background: rgba(255, 219, 77, 1);
        cursor: pointer;
    }
    .mobile-button__text {
        font: 12px proxima-regular;
        color: #445f7a;
    }
    .mobile-button:hover {
        background: rgba(255, 226, 111, 1);
    }
    .search-icon, .filter-icon {
        fill: #445f7a;
    }
    .filters-menu-bar {
        visibility: visible;
        position: fixed;
        top: 70px;
        z-index: 233;
        width: 99.2vw;
        background: rgb(248, 248, 251);
        border-bottom: 1px solid rgb(220, 220, 220);
    }

    .filters-menu-bar--hidden {
        visibility: hidden;
    }

    .filters-menu-bar--map {
        position: relative;
        overflow: hidden;
        margin-top: -16px;
    }

    .more {
        display: flex;
        height: 28px;
        font: 13px proxima-bold;
        letter-spacing: 0.01em;
        line-height: 28px;
    }
    .mark {
        margin: 11px 0 11px 5px;
        height: 6px;
        transition: transform .25s;
    }
    .mark__opened {
        transform: rotate(180deg);
    }
    .mark__inner {
        margin: -2px 0 0 0;
        width: 7px;
        height: 7px;
        transform: rotate(-45deg);
    }
    .mark__line-top {
        width: 2px;
        height: 7px;
        background: rgb(15, 42, 72);
        border-radius: 3px;
    }
    .mark__line-bot {
        width: 7px;
        height: 2px;
        background: rgb(15, 42, 72);
        margin-top: -2px;
        border-radius: 3px;
    }
    .more:hover .mark__line-top {
        background: rgb(250, 82, 50);
    }
    .more:hover .mark__line-bot {
        background: rgb(250, 82, 50);
    }

    .filter {
        flex: 1 1 0;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px 20px 0 20px;
        border-radius: 4px;
        background: rgb(248, 248, 251);
    }
    .container__title {
        height: 0;
        transition: height .25s;
    }
    .container__title__open {
        height: 45px;
    }
    .filter__title {
        height: 30px;
        margin-bottom: 15px;
        font: 18px proxima-bold;
        letter-spacing: 0.01em;
        line-height: 30px;
    }
    .filter__row {
        display: flex;
        width: 950px;
        justify-content: space-between;
        margin: 0 0 30px 2px;
    }
    .filter__row:last-of-type {
        margin: 0 0 0 2px;
    }

    .f-title {
        margin-bottom: 7px;
        height: 17px;
        line-height: 17px;
    }
    .filters__count {
        display: inline-block;
        vertical-align: baseline;
        position: relative;
        top: -10px;
        left: 4px;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        background: #ffe062;
        font: 10px proxima-regular;
        line-height: 16px;
        text-align: center;
    }
    .filter__clean {
        margin-left: 20px;
        font: 13px proxima-regular;
        color: rgb(180, 180, 180);
        cursor: pointer;
    }
    .filter__clean:hover {
        color: rgb(237, 119, 3);
    }
    .container {
        max-width: 100%;
        height: 28px;
        overflow: hidden;
        transition: height 0.15s;
        margin-bottom: 20px;
        background: rgb(248, 248, 251);
    }
    .container__open {
        height: 52px;
        margin-bottom: 30px;
    }
    .inner {
        display: flex;
        max-width: 100%;
        height: 52px;
        transform: translate(0, -24px);
        transition: transform .25s;
    }
    .inner__open {
        transform: translate(0, 0);
    }

    .container__full {
        max-width: 100%;
        height: 0;
        overflow: hidden;
        transition: height .25s;
    }
    .container__full__open {
        height: 294px;
        padding-bottom: 30px;
    }
    .inner__full {
        max-width: 100%;
        height: 134px;
        transform: translate(0, -134px);
        transition: transform .25s;
    }
    .inner__full__open {
        transform: translate(0, 0);
    }

    .button {
        margin-left: 30px;
        border: 0;
        height: 28px;
        padding: 0 25px;
        border-radius: 60px;
        font: 13px proxima-regular;
        background: rgba(255, 219, 77, 1);
        cursor: pointer;
    }
    .button:hover {
        background: rgba(255, 226, 111, 1);
    }

    .space {
        margin-right: 30px;
    }
    .space2 {
        margin-right: 28px;
    }
</style>